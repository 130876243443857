import { lazy } from "react";

//*Todas los screen que se crean deben ser export default function para funcionar con el lazy

//Screens
const HomeScreen = lazy(() =>
    import("../../views/administrador/home/HomeScreen")
);

//ADMINISTRADOR
const UserScreen = lazy(() => import("../../views/administrador/UserScreen"));

//TEACHERS
const TeacherScreen = lazy(() => import("../../views/teachers/TeacherScreen"));
const TeacherDetail = lazy(() => import("../../views/teachers/TeacherDetail"));

//teachers payroll v1
const PayrollScreen = lazy(() =>
    import("../../views/teachers/v1/payroll/PayrollScreen")
);
const TeacherPayroll = lazy(() =>
    import("../../views/teachers/v1/payroll/teacher/TeacherPayroll")
);
const TeacherPayrollDetail = lazy(() =>
    import("../../views/teachers/v1/payroll/teacher/TeacherPayrollDetail")
);

//teachers payroll v2
const CycleScreen = lazy(() =>
    import("../../views/operators/cycle/CycleScreen")
);
const CurriculaScreen = lazy(() =>
    import("../../views/operators/curriculums/CurriculaScreen")
);
const CurriculaEdit = lazy(() =>
    import("../../views/operators/curriculums/CurriculaEdit")
);
const SchoolSubjectScreen = lazy(() =>
    import("../../views/operators/school_subjects/SchoolSubjectScreen")
);
const CutsPayroll = lazy(() =>
    import("../../views/teachers/v2/payroll/CutsPayroll")
);
const TabulatorScreen = lazy(() =>
    import("../../views/teachers/v2/tabulators/TabulatorScreen")
);
const ModulesScreenTeacher = lazy(() =>
    import("../../views/teachers/v2/modules/ModulesScreen")
);
const TeacherPunch = lazy(() =>
    import("../../views/teachers/v2/payroll/punches/TeacherPunch")
);
const JustifyScreen = lazy(() =>
    import("../../views/teachers/v2/justify/JustifyScreen")
);
//Rutas de educacion a distancia
const EADScreen = lazy(() => import("../../views/teachers/EAD/EADScreen.jsx"));

//Fin rutas de educacion a distancia

//STUDENTS
const StudentsScreen = lazy(() =>
    import("../../views/students/StudentsScreen")
);
const StudentsScreenAdmission = lazy(() =>
    import("../../views/students/StudentsScreenAdmission")
);

const StudentDetail = lazy(() =>
    import("../../views/students/StudentDetail/StudentDetail")
);
const GenerateXMLScreen = lazy(() =>
    import("../../views/students/GenerateXMLScreen")
);
const OperatorClarificationScreen = lazy(() =>
    import("../../views/students/Aclarations/OperatorClarificationScreen")
);

//PROFESSIONAL_PRACTICES
const ProfessionalPracticesScreen = lazy(() =>
    import(
        "../../views/students/plans_and_study_programs/ProfessionalPracticesScreen"
    )
);
const ProfessionalPracticesDetail = lazy(() =>
    import(
        "../../views/students/plans_and_study_programs/ProfessionalPracticesDetail"
    )
);
//SERVICE_SOCIAL
const SocialServiceScreen = lazy(() =>
    import("../../views/students/plans_and_study_programs/SocialServiceScreen")
);
//PARTNERSHIPS
const PartnershipsScreen = lazy(() =>
    import("../../views/students/Partnerships/Partnerships")
);

//EMPLOYEES
const EmployeeScreen = lazy(() =>
    import("../../views/employees/EmployeeScreen")
);
const EmployeeDetail = lazy(() =>
    import("../../views/employees/EmployeeDetail")
);
const EmployeeReport = lazy(() =>
    import("../../views/employees/report/TechnicalSheetEmployee")
);

const DepartmentScreen = lazy(() =>
    import("../../views/employees/departments/DepartmentScreen")
);
const PunchesScreen = lazy(() =>
    import("../../views/employees/Punches/PunchesScreen")
);
const AllPunchesScreen = lazy(() =>
    import("../../views/employees/Punches/AllPunchesScreen")
);
const SchedulesScreen = lazy(() =>
    import("../../views/employees/schedule/SchedulesScreen")
);

const SchedulesForm = lazy(() =>
    import("../../views/employees/schedule/SchedulesForm")
);

//MANUALS
const ManualsScreen = lazy(() =>
    import("../../views/employees/manuals/ManualsScreen.jsx")
);

const PublicManualScreen = lazy(() =>
    import("../../views/employees/manuals/PublicManualScreen")
);

//FORMATS
const FormatScreen = lazy(() =>
    import("../../views/employees/formats/FormartScreen.jsx")
);
const PublicFormatScreen = lazy(() =>
    import("../../views/employees/formats/PublicFormatScreen.jsx")
);

//CALENDAR
const CalendarScreen = lazy(() =>
    import("../../views/employees/incidents/calendar/CalendarScreen")
);
//MODULES
const ModulesScreen = lazy(() =>
    import("../../views/administrador/modules/ModulesScreen")
);
//ROLES
const RolesScreen = lazy(() =>
    import("../../views/administrador/roles/RolesScreen")
);
//CYCLE STUDENTS REPORT
const CycleStudentsReportScreen = lazy(() =>
    import(
        "../../views/operators/cycle_student_report/cycleStudentReportScreen"
    )
);
//ATTENDANCES REPORT
const AttendancesReportScreen = lazy(() =>
    import("../../views/operators/attendances_report/AttendancesReportScreen")
);
//CLASS LOAD REPORT
const ClassLoadReportScreen = lazy(() =>
    import("../../views/operators/class_load_report/ClassLoadReportScreen")
);
//HOURS BY SUBJECT REPORT
const SubjectHoursReportScreen = lazy(() =>
    import(
        "../../views/operators/subject_hours_report/SubjectHoursReportScreen"
    )
);
//CLASS SCHEDULES REPORT
const ClassSchedulesReportScreen = lazy(() =>
    import(
        "../../views/operators/class_schedules_report/ClassSchedulesReportScreen"
    )
);
//INCIDENTS
const IncidentsScreen = lazy(() =>
    import("../../views/employees/incidents/RH/IncidentsScreen")
);
const ReportPayrollScreen = lazy(() =>
    import("../../views/employees/incidents/reports/ReportPayrollScreen")
);
const IncidentLog = lazy(() =>
    import("../../views/employees/incidents/reports/IncidentLog")
);

//CORPORATIVE
const VacationScreen = lazy(() =>
    import("../../views/employees/corporative/VacationScreen")
);
//AREA_DIRECTOR
const AreaDirectorsScreen = lazy(() =>
    import("../../views/employees/incidents/area_directors/AreaDirectorsScreen")
);
//SUPPORT_TI
const SupportTIScreen = lazy(() =>
    import("../../views/employees/support/SupportTIScreen")
);
const SupportTIByUser = lazy(() =>
    import("../../views/employees/support/SupportTIByUser")
);
//set aside rooms
const EventScreen = lazy(() =>
    import("../../views/employees/events/EventScreen")
);

// Certificados
const ElectronicTitlesScreen = lazy(() =>
    import("../../views/students/electronic_titles/ElectronicTitlesScreen")
);

//Modulo compras
const ProviderScreen = lazy(() =>
    import("../../views/employees/shopping/providers/ProviderScreen")
);
const PurchaseScreen = lazy(() =>
    import("../../views/employees/shopping/purchases/PurchaseScreen")
);
const BudgetScreen = lazy(() =>
    import("../../views/employees/shopping/budgets/BudgetScreen")
);

//Perfil de usuario

const ProfileScreen = lazy(() =>
    import("../../views/employees/profile/ProfileScreen")
);

const GroupScreen = lazy(() =>
    import("../../views/teachers/v2/modules/groups/GroupScreen")
);

//Configuraciones intranet
const SettingScreen = lazy(() =>
    import("../../views/administrador/config/SettingScreen")
);

//Registrar nueva empresa - intranet
const EnterprisesScreen = lazy(() =>
    import("../../views/administrador/config/EnterprisesScreen")
);

//Configuraciones intranet
const OrganizationScreen = lazy(() =>
    import("../../views/employees/organization/OrganizationScreen")
);

//Rutas de grupos de estudiantes
const AcademicGroupsScreen = lazy(() =>
    import("../../views/operators/academic_groups/AcademicGroupsScreen")
);

//Rutas de materias extracurriculares
const ExtracurricularSubjectsScreen = lazy(() =>
    import(
        "../../views/operators/extracurricular_subjects/ExtracurricularSubjects"
    )
);

const AcademicGroupsEdit = lazy(() =>
    import("../../views/operators/academic_groups/AcademicGroupsEdit")
);

//Rutas de infraestructura
const BuildingScreen = lazy(() =>
    import("../../views/operators/Infrastructure/BuildingScreen")
);

const BuildingEdit = lazy(() =>
    import("../../views/operators/Infrastructure/BuildingEdit")
);

const ClassroomScreen = lazy(() =>
    import("../../views/operators/Infrastructure/ClassroomScreen")
);

const ClassroomEdit = lazy(() =>
    import("../../views/operators/Infrastructure/ClassroomEdit")
);
const CoordinationScreen = lazy(() =>
    import("../../views/operators/coordinations/CoordinationScreen")
);

//Curricula tabulators routes
const CurriculaTabulatorScreen = lazy(() =>
    import(
        "../../views/operators/curricula_tabulators/CurriculaTabulatorsScreen"
    )
);

const ScholarshipsScreen = lazy(() =>
    import("../../views/operators/Scholarships/ScholarshipScreen")
);

//RECEIVED PAYMENTS
const PaymentsScreen = lazy(() =>
    import("../../views/operators/payments/PaymentsScreen")
);

const StudentsReport = lazy(() =>
    import("../../views/students/report/StudentsReport")
);

const InscriptionSummaryScreen = lazy(() =>
    import("../../views/operators/inscriptionSummary/InscriptionSummaryScreen")
);

const ConventionScreen = lazy(() =>
    import("../../views/operators/conventions/ConventionScreen")
);

const PositiveBalanceScreen = lazy(() =>
    import("../../views/operators/positive_valance/PositiveBalanceScreen")
);

//school control reports
const SchoolControlReportsScreen = lazy(() =>
    import("../../views/operators/schoool_control/ReportsScreen")
);

//*school control reports
const GradeUploadReportScreen = lazy(() =>
    import(
        "../../views/operators/grade_upload_report/GradeUploadReportScreen.jsx"
    )
);

const TeachersByCycleReportScreen = lazy(() =>
    import(
        "../../views/operators/teachers_by_cycle/TeachersByCycleReportScreen"
    )
);

// Reporte de adeudos por mes
const DebtsByMonthReportsScreen = lazy(() =>
    import("../../views/operators/debts_by_month/DebtsByMonthReportsScreen")
);

// Reporte de becas
const ScholarshipsReportsScreen = lazy(() =>
    import(
        "../../views/operators/scholarships_report/ScholarshipsReportsScreen"
    )
);

// Reporte de calificaciones por materia
const GradesBySubjectScreen = lazy(() =>
    import("../../views/operators/grades_by_subject/GradesBySubjectScreen")
);

// Descargas de kardex
const DownloadKardexScreen = lazy(() =>
    import("../../views/operators/download_kardex/DownloadKardexScreen")
);

//GradesEditScreen
const GradesEditScreen = lazy(() =>
    import("../../views/operators/grades_edit/GradesEditScreen")
);

// Reporte de inscripciones por ciclo y carrera
const InscriptionsByCycleReportsScreen = lazy(() =>
    import(
        "../../views/operators/inscriptions_by_cycle_reports/InscriptionsByCycleReportsScreen"
    )
);

// Reporte de placas vehiculares
const VehiclePlatesReportsScreen = lazy(() =>
    import(
        "../../views/operators/vehicle_plates_report/VehiclePlatesReportsScreen"
    )
);

// Reporte de promedios generales
const AveragesReportsGeneralScreen = lazy(() =>
    import(
        "../../views/operators/averages_reports/AveragesReportsGeneralScreen.jsx"
    )
);

// Reporte de estatus académico
const AcademicStatusReportsScreen = lazy(() =>
    import(
        "../../views/operators/academic_status_reports/AcademicStatusReportsScreen"
    )
);

// Reporte de escuelas de procedencia de estudiantes
const OriginSchoolsReportScreen = lazy(() =>
    import(
        "../../views/operators/origin_school_reports/OriginSchoolReportsScreen"
    )
);

//Reports
const StudentGradesMapReport = lazy(() =>
    import("../../views/students/report/StudentReportGradesMap")
);

const DiscountScreen = lazy(() =>
    import("../../views/operators/Discount/DiscountScreen")
);

//Listas de distribución
const DistributionListsScreen = lazy(() =>
    import("../../views/operators/distribution_lists/DistributionListsScreen")
);

//Avisos
const NewsScreen = lazy(() => import("../../views/operators/news/NewsScreen"));

//*Aclaraciones
const AclarationsScreen = lazy(() =>
    import("../../views/students/Aclarations/OperatorClarificationScreen")
);

const ReviewDocumentsScreen = lazy(() =>
    import("../../views/operators/reviewDocuments/ReviewDocumentsScreen")
);

const ReviewStatusesScreen = lazy(() =>
    import("../../views/operators/reviewStatus/ReviewStatusScreen")
);

const SocialServiceDetail = lazy(() =>
    import("../../views/students/plans_and_study_programs/SocialServiceDetail")
);
const MarketCashierScreen = lazy(() =>
    import("../../views/operators/marketCashier/MarketCashierScreen")
);
const MarketCashierPayments = lazy(() =>
    import("../../views/operators/marketCashier/MarketCashierPayments")
);
const MassiveUpdateStudentsScreen = lazy(() =>
    import(
        "../../views/operators/massive_updates_students/MassiveUpdateStudentsScreen"
    )
);
const SchoolOfOriginScreen = lazy(() =>
    import("../../views/operators/schools_of_origins/SchoolOfOriginScreen")
);
const MasiveInvoiceScreen = lazy(() =>
    import("../../views/operators/payments/MassiveInvoiceScreen")
);

const GenerateReceiptScreen = lazy(() =>
    import("../../views/operators/payments/GenerateReceiptScreen")
);

const ImportedFiles = lazy(() =>
    import("../../views/operators/payments/ImportedFiles")
);

const InvoiceManualScreen = lazy(() =>
    import("../../views/operators/payments/ManualinvoiceScreen")
);
const ChargeBySubjectScreen = lazy(() =>
    import("../../views/operators/charge_by_subject/ChargeBySubjectScreen")
);
const EndOfMonthScreen = lazy(() =>
    import("../../views/operators/endOfMonth/EndOfMonthScreen.jsx")
);
const ServicesByConceptReportScreen = lazy(() =>
    import("../../views/operators/services_by_concept_report/ServicesByConceptReportScreen")
);
const PastduePortfolioScreen = lazy(() =>
    import(
        "../../views/operators/Past_due_portfolio/PastduePortfolioScreen.jsx"
    )
);

export const UsersRoutes = {
    HomeScreen,
    UserScreen,
    TeacherScreen,
    TeacherDetail,
    TeacherPayroll,
    PayrollScreen,
    CycleScreen,
    TeacherPayrollDetail,
    StudentsScreen,
    StudentsScreenAdmission,
    StudentDetail,
    GenerateXMLScreen,
    OperatorClarificationScreen,
    ProfessionalPracticesScreen,
    ProfessionalPracticesDetail,
    SocialServiceScreen,
    PartnershipsScreen,
    EmployeeScreen,
    EmployeeDetail,
    EmployeeReport,
    DepartmentScreen,
    PunchesScreen,
    AllPunchesScreen,
    SchedulesScreen,
    SchedulesForm,
    ManualsScreen,
    EADScreen,
    JustifyScreen,
    PublicManualScreen,
    FormatScreen,
    PublicFormatScreen,
    CalendarScreen,
    ModulesScreen,
    IncidentsScreen,
    ReportPayrollScreen,
    PastduePortfolioScreen,
    IncidentLog,
    VacationScreen,
    AreaDirectorsScreen,
    SupportTIScreen,
    SupportTIByUser,
    EventScreen,
    ElectronicTitlesScreen,
    CurriculaScreen,
    CurriculaEdit,
    SchoolSubjectScreen,
    CutsPayroll,
    TabulatorScreen,
    ModulesScreenTeacher,
    TeacherPunch,
    ProviderScreen,
    PurchaseScreen,
    BudgetScreen,
    ProfileScreen,
    GroupScreen,
    SettingScreen,
    EnterprisesScreen,
    OrganizationScreen,
    AcademicGroupsScreen,
    ExtracurricularSubjectsScreen,
    AcademicGroupsEdit,
    BuildingScreen,
    BuildingEdit,
    ClassroomScreen,
    ClassroomEdit,
    CoordinationScreen,
    CurriculaTabulatorScreen,
    ScholarshipsScreen,
    PaymentsScreen,
    StudentsReport,
    InscriptionSummaryScreen,
    ConventionScreen,
    PositiveBalanceScreen,
    SchoolControlReportsScreen,
    GradesEditScreen,
    StudentGradesMapReport,
    DiscountScreen,
    DistributionListsScreen,
    NewsScreen,
    AclarationsScreen,
    ReviewDocumentsScreen,
    ReviewStatusesScreen,
    SocialServiceDetail,
    MarketCashierScreen,
    MarketCashierPayments,
    GradeUploadReportScreen,
    TeachersByCycleReportScreen,
    DebtsByMonthReportsScreen,
    ScholarshipsReportsScreen,
    GradesBySubjectScreen,
    DownloadKardexScreen,
    InscriptionsByCycleReportsScreen,
    VehiclePlatesReportsScreen,
    AveragesReportsGeneralScreen,
    AcademicStatusReportsScreen,
    MassiveUpdateStudentsScreen,
    SchoolOfOriginScreen,
    MasiveInvoiceScreen,
    InvoiceManualScreen,
    GenerateReceiptScreen,
    ImportedFiles,
    ChargeBySubjectScreen,
    EndOfMonthScreen,
    ServicesByConceptReportScreen,
    OriginSchoolsReportScreen,
    RolesScreen,
    CycleStudentsReportScreen,
    AttendancesReportScreen,
    ClassLoadReportScreen,
    ClassSchedulesReportScreen,
    SubjectHoursReportScreen,
};
